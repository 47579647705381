<template>
    <div>
        <van-empty
            class="custom-image"
            image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
            description="404页面找不到哦"
            />
    </div>
</template>

<script>
import Vue from 'vue';
import { Empty } from 'vant';

Vue.use(Empty);
export default {

}
</script>

<style scoped>
    .custom-image .van-empty__image {
        width: 45px;
        height: 45px;
    }

</style>