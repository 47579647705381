import { render, staticRenderFns } from "./404.vue?vue&type=template&id=65c35c2f&scoped=true&"
import script from "./404.vue?vue&type=script&lang=js&"
export * from "./404.vue?vue&type=script&lang=js&"
import style0 from "./404.vue?vue&type=style&index=0&id=65c35c2f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65c35c2f",
  null
  
)

export default component.exports